import { h } from 'preact';
import { useState, useCallback } from 'preact/hooks';
import style from './style';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: 'black'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(false);
  const openMenu = useCallback(() => setMenuOpen(true), [setMenuOpen])
  const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen])

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={openMenu} >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Treasury
          </Typography>
        </Toolbar>
      </AppBar>

      <Menu
        keepMounted
        open={menuOpen}
        onClose={closeMenu}
      >
        <MenuItem />
      </Menu>
    </div>
  );
}
